import logo from "./logo.webp"

const  clientData = {
   client_entity: 4,
   attorney_firm: 'frascognalaw.com',
    attorney_name: 'Marty Frascogna',
    attorney_number: '601-400-3666',
    attorney_email: 'mff@frascognalaw.com',
    client_video_source: 'https://erc-videos.s3.amazonaws.com/ertc_-_frascogna_law+(720p).mp4',
    mainColor: '#242424',
    secondaryColor: '#2C3754',
    siteLink: 'http://www.frascognalaw.com/',
    logo
}

export default clientData